$(document).ready(function() {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function(){
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function(e){
		var target = $(this).attr("target"),
			url = $(this).attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    }).click(function(){
		var target = $(this).find("a.js-click-item-link").attr("target"),
			url = $(this).find("a.js-click-item-link").attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    });​



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Scroll-To
    // *
    // * Smooth-Scroll to targets on page
    // *
    $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                scrollTo(target);
            return false;
            }
        }
    });

    function scrollTo(element) {
        $('html, body').animate({
            scrollTop: element.offset().top - 100
        }, 1000);
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 60; // Distance from Browserbottom where the animation should start

    function fadeInElements(){
        var viewPort = $(window).scrollTop() + $(window).height();

        $(".animateIn:visible").each(function(){
            var elementTop = $(this).offset().top;

            if ((elementTop + offset) <= viewPort) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function() {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function() {
        if(location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        }else {
            $(this).attr('target','_blank');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * sticky Header
    // *
    // *
    $(window).on("load scroll", function(){
        if($(window).scrollTop() >= 30) {
            $(".js-header").addClass("sticky");
        }else {
            $(".js-header").removeClass("sticky");
        }
    });




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * nav mobile
    // *
    // *
    $(".js-nav-button").click(function () {
        $(".js-nav-mobile").toggleClass("active");
        $('html, body').toggleClass('freeze');
    })

    $(".js-nav-mobile li.parent svg").click(function() {
        $(this).toggleClass("open").parent(".js-nav-mobile-parent").siblings("ul").slideToggle();
    })

    $(".js-nav-mobile li.parent.active").find("svg").trigger("click");



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // *  text-tabs
    // *
    // *
    // set wrapper min height to largest element
    function getMaxHeight(className) {
        let maxHeight = 0;
        $(className).each(function() {
          const height = $(this).outerHeight();
          if (height > maxHeight) {
              maxHeight = height;
          }
        });
        return maxHeight;
    }

    $(".highlightSwitcher__wrapper").each(function(){
        var maxHeight = getMaxHeight($(this).find(".js-tabs-content"));
        $(this).css("min-height", maxHeight);
    });


    $(".js-tabs-button").mouseenter(function(event) {
        var index = $(this).index();
        $(this).addClass("active").siblings().removeClass("active");
        $(".js-tabs-content:nth-child(" + (index + 1) + ")").show().siblings().hide();
        $(".js-tabs-background:nth-child(" + (index + 1) + ")").show().siblings().hide();


        // remove auto class if click is human and not triggered
        if (event.originalEvent !== undefined) {
            clearInterval(tabAutoplay);
        }
    });

    // trigger click on first child
    $(".js-tabs-button:first").trigger("mouseenter");

    // autoplay
    var tabAutoplay = setInterval(function(){
        var $nextButton = $(".js-tabs-button.active").next();

        // if last button is active
        if (!$nextButton.length) {
            $nextButton = $(".js-tabs-button:first");
        }

        $nextButton.trigger("mouseenter");
    }, 5000);​




    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * view port
	// *
	// *
	var offset = 80; /* Abstand vom Browserbottom, wenn Effekt einsetzen soll */

	function inViewpoint(){
		var viewPort = $(window).scrollTop() + $(window).height();

		$(".viewpoint-element:visible").each(function(){
			var elementTop = $(this).offset().top;

			if ((elementTop + offset) <= viewPort) {
				var delay = $(this).data("delay");

				// check for fact class - if in viepoint fire countUp (once)
				if ($(this).hasClass("js-count-up") && !$(this).hasClass("in-viewpoint")) {
					countUp();
				}

				$(this).addClass("in-viewpoint");
			}
		});
	}
	$(window).scroll(function() {
		inViewpoint();
	});
	inViewpoint();



	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * countUp
	// *
	// *
	function countUp() {

		// count up
		var options = {
		  useEasing: true,
		  useGrouping: true,
		  separator: '.',
		  decimal: '.',
		};

		$(".js-count-up").each(function(){
			var counterID = $(this).attr("id");
				countTo = $(this).data("count");
			var count = new CountUp(counterID, 0, countTo, 0, 4, options);
			count.start();
		});
	}




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * textSlider
    // *
    var $textSlider = $(".js-textslider");

    if ($textSlider.length) {
        $textSlider.each(function(){
            var $textSliderWrapper = $(this).parents(".js-textslider-wrapper"),
                $textSliderDots = $textSliderWrapper.find(".js-textslider-dots");

            $(this).slick({
                infinite: true,
                fade: false,
                dots: true,
                arrows: true,
                autoplay: true,
                autoplaySpeed: 3000,
                waitForAnimate: false,
                prevArrow: $textSliderWrapper.find(".js-textslider-nav-prev"),
                nextArrow: $textSliderWrapper.find(".js-textslider-nav-next"),
                draggable: false,
                responsive: [],
                rows: 0,
                appendDots: $textSliderDots,
            });
        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * logoSlider
    // *
    var $logoSlider = $(".js-logoslider"),
        $logoSliderWrapper = $logoSlider.parents(".js-logoslider-wrapper"),
        $logoSliderDots = $logoSliderWrapper.find(".js-logoslider-dots");

    if ($logoSlider.length) {
        $logoSlider.slick({
            infinite: true,
            fade: false,
            dots: true,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 3000,
            waitForAnimate: false,
            draggable: false,
            rows: 0,
            slidesToShow: 4,
            slidesToScroll: 1,
            appendDots: $logoSliderDots,
            responsive: [
               {
                 breakpoint: 1050,
                 settings: {
                   slidesToShow: 2,
                   slidesToScroll: 1
                 }
               },
               {
                 breakpoint: 700,
                 settings: {
                   slidesToShow: 1,
                   slidesToScroll: 1
                 }
               }
           ]
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // *  tabs
    // *
    // *
    $(".js-tab").click(function(event) {
        var index = $(this).index();
        $(this).addClass("active").siblings().removeClass("active");
        $(".js-tab-content:nth-child(" + (index + 1) + ")").show().siblings().hide();
        $(".js-tab-inner:first-child").trigger("click");
    });

    // trigger click on first child
    $(".js-tab:first").trigger("click");


    $(".js-tab-inner").click(function(event) {
        var index = $(this).index();
        $(this).addClass("active").siblings().removeClass("active");
        $(".js-tab-inner-content:nth-child(" + (index + 1) + ")").show().siblings().hide();
    });

    // trigger click on first child
    $(".js-tab-inner:first-child").trigger("click");



    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * slider
	// *
	// *
	var $introSlider = $(".js-intro-slider");

	if ($introSlider.length){
		$introSlider.each(function(){
			$(this).slick({
				fade: false,
				dots: false,
				infinite: true,
				arrows: true,
				autoplay: true,
				autoplaySpeed: 5000,
				speed: 1000,
				prevArrow: $(this).parents(".js-intro-slider-wrapper").find(".js-intro-slider-nav-prev"),
				nextArrow: $(this).parents(".js-intro-slider-wrapper").find(".js-intro-slider-nav-next"),
			});
		});
	}



    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * imageAccordion
	// *
	// *
    if ($(window).width() > 1200) {
        $(".js-image-accordion-button:first-child").click(function(){
            $(this).parent(".js-image-accordion-parent").addClass("open").siblings().removeClass("open");
        });
    }
    else {
        $(".js-image-accordion-button:nth-child(2)").click(function(){
            $(this).parent(".js-image-accordion-parent").addClass("open").find(".js-image-accordion-content").slideDown().parents(".js-image-accordion-parent").siblings().removeClass("open").find(".js-image-accordion-content").slideUp();
        });
    }

    // trigger click on first child
    $(".js-image-accordion-parent:first-child .js-image-accordion-button").trigger("click");



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * filter products
    // *
    // *
    if ($(".js-productfilter").length) {
        var mixer = mixitup('.js-filter-elements', {
            selectors: {
                target: '.js-filter-element'
            }
            // multifilter: {
            //     enable: true // enable the multifilter extension for the mixer
            // }
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * radio form
    // *
    // *
    $(".js-radio-button").click(function() {
        var index = $(this).index();
        $(".js-radio-form").eq(index).show().siblings().hide();
    })

    // trigger first element
    $(".js-radio-button").eq(0).trigger("click");
    $(".js-radio-button.Wassergekuehlt").eq(0).trigger("click");
    $(".js-radio-button.Luftgekuehlt").eq(1).trigger("click");



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * accordion form
    // *
    // *
    $(".js-form-accordion-button").change(function() {

        if ( $(".js-form-accordion-button option:selected").val() == "keine" ) {
            $(".js-form-accordion-button").parents(".js-form-accordion").siblings(".js-form-accordion-field").hide();
        } else {
            $(".js-form-accordion-button").parents(".js-form-accordion").siblings(".js-form-accordion-field").show();
        }

    });





      







});
